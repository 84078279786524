import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className="relative flex min-h-screen flex-col justify-center text-center overflow-hidden bg-indigo-50 py-6 sm:py-12">
      <div className='sm:mx-auto sm:max-w-lg'>
        <h1 className='mb-4 text-6xl'>404</h1>
        <h2 className='mb-6 text-2xl'>Page Not Found.</h2>
        <Link to={'/'} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800">Go Home</Link>
      </div>
    </div>
  )
}