import React from 'react'

function Input({ label = '', name = '', type = 'text', value = '', onChange = null, placeholder = '', required = false, labelClass = '', inputClass = '', index = '' }) {
  return (
    <label className={`block ${labelClass}`}>
      <span className={`${required ? "after:content-['*'] after:ml-0.5 after:text-red-500" : ''} block text-sm font-medium text-slate-700 capitalize`}>
        {label ? label : name}
      </span>
      <input type={type} id={index} name={name} value={value} onChange={onChange} className={`mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 ${inputClass}`} placeholder={placeholder} required={required} />
    </label>
  )
}

export default Input