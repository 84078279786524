import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Outlet, Route, useNavigate, useSearchParams } from 'react-router-dom';
// routes
import RegistrationForm from './pages/RegistrationForm'
import LoginForm from './pages/LoginForm'
import ForgotPassword from './pages/ForgotPassword'
import Dashboard from './pages/Dashboard';
import Contacts from './pages/Contacts';
import ContactDetails from './components/ContactDetails';
import Customers from './pages/Customers';
import Leads from './pages/Leads'
import Prospects from './pages/Prospects'
import Settings from './pages/Settings';
import NotFound from './pages/NotFound';

import SideBar from './components/SideBar'

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';
import * as CryptoJS from 'crypto-js';
import Location from './pages/Location';
import LocationDetails from './components/LocationDetails';

const AppLayout = () => {
  const [token, setToken] = useState(sessionStorage.getItem('token'))
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('verify')) {
      let decData = CryptoJS.enc.Base64.parse(JSON.parse(searchParams.get('verify'))).toString(CryptoJS.enc.Utf8)
      let user = CryptoJS.AES.decrypt(decData, 'fMH/0A]@E4Zm)b7;#"rR+&`LweX$Ow').toString(CryptoJS.enc.Utf8)
      let userParsed = JSON.parse(user)
      console.log(userParsed)
      sessionStorage.setItem('token', userParsed.token)
      sessionStorage.setItem('email', userParsed.email)
      sessionStorage.setItem('id', userParsed.id)
      sessionStorage.setItem('isLoggedIn', "true")
    }
    if (!searchParams.get('verify') && !token) {
      navigate('/');
    }
    setToken(sessionStorage.getItem('token'))
  }, [sessionStorage])

  if (!token) {
    return null;
  }

  return (
    <section className="flex">
      <SideBar />
      <div className="w-full text-xl text-gray-900 font-semibold">
        <Outlet />
      </div>
      <ToastContainer />
    </section>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginForm />,
  },
  {
    path: '/register',
    element: <RegistrationForm />,
  },
  {
    path: '/forgotPassword',
    element: <ForgotPassword />,
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/entity',
        element: <Contacts />,
        children: [
          {
            path: '/entity/:id',
            element: <ContactDetails />
          },
        ]
      },
      {
        path: '/location',
        element: <Location />,
        children: [
          {
            path: '/location/:id',
            element: <LocationDetails />
          },
        ]
      },
      {
        path: '/customers',
        element: <Customers />,
      },
      {
        path: '/leads',
        element: <Leads />,
      },
      {
        path: '/prospects',
        element: <Prospects />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />,
  }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <BrowserRouter>
  //     <App />
  //   </BrowserRouter>
  // </React.StrictMode>

  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
