import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

export default function RegistrationForm() {

  const [errMessage, setErrMessage] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const [isPasswordSeen, setIsPasswordSeen] = useState(false)

  const [registerData, setRegisterData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    if (e.target.type === 'email') {
      const registerEmail = document.getElementById('register-email')
      registerEmail.classList.remove('text-red-500', 'border-red-500')
      registerEmail.classList.add('border-slate-300')
    }
    setRegisterData(prevFormData => (
      {
        ...prevFormData,
        [e.target.name]: e.target.value
      }
    ))
  }

  const registrationForm = useRef(null)

  const onRegister = (e) => {
    e.preventDefault()
    setErrMessage(null)

    const registerBtn = document.getElementById('register-btn')
    registerBtn.setAttribute('disabled', '')
    registerBtn.classList.add('disabled:opacity-70')
    registerBtn.innerText = 'REGISTERING...'

    let data = {
      fname: registerData.firstName,
      lname: registerData.lastName,
      email: registerData.email,
      password: registerData.password
    }

    data = JSON.stringify(data)
    registrationForm.current.reset()
    fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 409 || !data.success) {
          setErrMessage(data.message)
          registerBtn.removeAttribute('disabled', '')
          registerBtn.classList.remove('disabled:opacity-70')
          registerBtn.innerText = 'REGISTER'

          const registerEmail = document.getElementById('register-email')
          registerEmail.classList.remove('border-slate-300')
          registerEmail.classList.add('text-red-500', 'border-red-500')
        }
        // registrationForm.reset()
        if (data.success) {
          setIsRegistered(true)
          // navigate('/')
        }
      })
  }

  return (
    <div className="w-full mx-auto flex">
      <div className='md:w-1/2 flex-none min-h-screen flex-col justify-center relative'>
        <img src='https://images.unsplash.com/photo-1611532736597-de2d4265fba3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80' alt='iPad on table with drawsing in the screen' className='absolute w-full h-full object-cover' />
      </div>

      <div className='w-full md:w-1/2 flex min-h-screen flex-col justify-center p-10 md:p-20 lg:p-40'>
        {!isRegistered ?
          (
            <>
              <h1 className="text-3xl">
                Create your Account ✨
              </h1>

              <form ref={registrationForm} onSubmit={onRegister}>
                <div className="flex justify-between my-5 gap-x-8">
                  <label className="block w-full">
                    <span className="block text-sm font-medium text-slate-700">First Name</span>
                    <input
                      type="text"
                      name='firstName'
                      value={registerData.firstName}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    "/>
                  </label>
                  <label className="block w-full">
                    <span className="block text-sm font-medium text-slate-700">Last Name</span>
                    <input
                      type="text"
                      name='lastName'
                      value={registerData.lastName}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    "/>
                  </label>
                </div>

                <label className="block my-5">
                  <span className="block text-sm font-medium text-slate-700">Email</span>
                  <input
                    type="email"
                    id="register-email"
                    name='email'
                    value={registerData.email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"/>
                </label>
                <label className="block my-5">
                  <span className="block text-sm font-medium text-slate-700">Password</span>
                  <div className="relative">
                    <input
                      // type="password"
                      type={`${isPasswordSeen ? 'text' : 'password'}`}
                      name='password'
                      value={registerData.password}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                    // className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    // focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    // disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    // invalid:border-pink-500 invalid:text-pink-600
                    // focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                    {registerData.password &&
                      <button type="button" className="absolute right-2 top-2.5" onClick={() => setIsPasswordSeen(!isPasswordSeen)}>
                        {isPasswordSeen ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      </button>
                    }
                  </div>
                </label>

                {errMessage && <p className="text-red-500 mb-5">{errMessage}</p>}

                <div className='flex justify-between'>
                  <button type="submit" id="register-btn" className="btn-primary  uppercase px-6">
                    Register
                  </button>
                </div>
              </form>
            </>
          ) :
          (
            <h1 className="text-3xl">Thank you! ✨</h1>
          )
        }

        <hr className='my-5' />
        <p>Have an account? <Link to='/' className="underline text-pink-400 hover:text-pink-600">Log In</Link></p>

      </div>
    </div>
  )
}