// import { useState } from 'react'
import { Link } from "react-router-dom"

export default function ForgotPassword() {
  const onSendResetLink = (e) => {
    e.preventDefault()
  }

  return (
    <div className="w-full mx-auto flex">
      <div className='md:w-1/2 flex-none min-h-screen flex-col justify-center relative'>
        <img src='https://images.unsplash.com/photo-1611532736597-de2d4265fba3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80' alt='iPad on table with drawsing in the screen' className='absolute w-full h-full object-cover' />
      </div>

      <div className='w-full md:w-1/2 flex min-h-screen flex-col justify-center p-10 md:p-20 lg:p-40'>
        <h1 className="text-3xl">
          Reset your Password ✨
        </h1>
        <form onSubmit={onSendResetLink}>
          <label className="block my-5">
            <span className="block text-sm font-medium text-slate-700">Email</span>
            <input type="email" name='inputEmail' className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500
            "/>
          </label>

          <div className='flex justify-between'>
            <button type="submit" className="btn-primary uppercase px-6">
              Send Reset Link
            </button>
          </div>
        </form>

        <hr className='my-5' />

        <p>Have an account? <Link to='/' className="text-pink-400 hover:text-pink-600">Log In</Link></p>
      </div>
    </div>
  )
}