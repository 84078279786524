import React, { useState, useEffect } from "react";
import { HiOutlineUser } from 'react-icons/hi'
import defaultImage from '../default company image.png'


export default function Customers() {
  const authToken = sessionStorage.getItem("token")
  const [isLoading, setIsLoading] = useState(true)
  const [customers, setCustomers] = useState([])

  const getCustomers = () => {
    setIsLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/concepts/list?type=crmContactInfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then(res => res.json())
      .then(data => {
        setIsLoading(false)
        const customersData = data.data.filter(data => data.data.crmContactInfo.isActive == 'True' && data.data.crmContactInfo.tag == 'client')
        customersData.sort((a, b) => a.data.crmContactInfo.name.localeCompare(b.data.crmContactInfo.name))
        setCustomers(customersData)
      })
  }

  const customerList = customers.map(customer => {
    const customerData = customer.data.crmContactInfo
    const imgUrl = customerData.avatar ? customerData.avatar : defaultImage;

    return (
      <tr key={customer.id}>
        <td className="p-2 whitespace-nowrap">
          <div className="flex items-center">
            <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
              <img className="rounded-full w-10 h-10" src={imgUrl} alt={customerData?.name} />
            </div>
            <div className="font-medium text-gray-800">{customerData?.name}</div>
          </div>
        </td>
        <td className="p-2 whitespace-nowrap">
          <div className="text-left">
            <a href={`mailto:${customerData?.email}`} className="text-indigo-400 hover:text-indigo-600">
              {customerData?.email}
            </a>
          </div>
        </td>
        <td className="p-2 whitespace-nowrap">
          <div className="text-left">
            <a href={`tel:${customerData?.phone}`} className="text-indigo-400 hover:text-indigo-600">
              {customerData?.phone}
            </a>
          </div>
        </td>
        {customer?.data?.crmContactInfo?.address?.[0] &&
          <td className="p-2 whitespace-nowrap">
            <div className="text-left">{customer?.data?.crmContactInfo?.address?.[0]?.street} {customer?.data?.crmContactInfo?.address?.[0]?.city} {customer?.data?.crmContactInfo?.address?.[0]?.country}</div>
          </td>
        }
      </tr>
    )
  })


  const emptyCustomerList = (
    <tr>
      <td colSpan='4' className="py-16 px-4 text-center">
        <HiOutlineUser size={64} className='bg-gray-200 p-4 rounded-full inline-block' />
        <p className='text-base mt-4'>There is no customer yet.</p>
      </td>
    </tr>
  )

  const loadingState = (
    <tr>
      <td colSpan='4' className="py-16 px-4 text-center">
        <p className='text-base mt-4'>Loading...</p>
      </td>
    </tr>
  )

  useEffect(() => {
    // return () => {
    getCustomers()
    // }
  }, [])

  return (
    <div className="container mx-auto p-8">
      <div className="bg-white px-6 py-4 rounded-xl">

        <div className="grid grid-cols-8">
          <section className="  col-span-8 overflow-y-auto">
            <div className="h-full">
              <div className="w-full mx-auto bg-white rounded-sm">
                <header className="p-4 border-b border-gray-100">
                  <h2 className="font-semibold text-gray-800">Customers ✨</h2>
                </header>
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full">
                      <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                        <tr>
                          <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-left">Name</div>
                          </th>
                          <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-left">Email</div>
                          </th>
                          <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-left">Phone</div>
                          </th>
                          <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-left">Country</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">

                        {!isLoading ?
                          (customerList.length ?
                            customerList : emptyCustomerList
                          ) : loadingState
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}