import React, { useEffect, useState } from 'react'
import { HiOutlineLogout } from 'react-icons/hi'
import { useNavigate } from "react-router-dom";

export default function Settings() {
  const navigate = useNavigate()

  const [userData, setUserData] = useState({})
  const userEmail = sessionStorage.getItem('email')

  const getUserData = () => {
    const userId = sessionStorage.getItem('id')
    const authToken = sessionStorage.getItem('token')

    fetch(`${process.env.REACT_APP_API_URL}/concepts?id=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then(res => res.json())
      .then(data => {
        const userInfo = data.data.the_entity.the_person
        setUserData({
          firstName: userInfo.first_name,
          lastName: userInfo.last_name
        })
      })
  }

  useEffect(() => {
    // return () => {
    getUserData()
    // }
  }, [])

  const logout = (e) => {
    e.preventDefault()
    sessionStorage.clear()
    navigate('/')
  }
  return (
    <div className="p-4">
      <h1>Settings ✨</h1>

      <br />
      <p>First Name: {userData.firstName}</p>
      <p>Last Name: {userData.lastName}</p>
      <p>Email: {userEmail}</p>

      <button type='button' onClick={logout} className='bg-red-400 text-white uppercase text-sm leading-6 font-medium py-2 px-4 rounded-lg hover:bg-red-600 mt-5'><HiOutlineLogout size={20} className='inline-block mr-2' />Log out</button>
    </div>
  )
}