import {
    BsFillTelephoneFill, BsFillEnvelopeFill,
    BsFillFileEarmarkTextFill, BsCircleFill, BsFillFileEarmarkExcelFill, BsMapFill
} from 'react-icons/bs'
import { FaMinus, FaPlus, FaUserEdit, FaUserMinus } from 'react-icons/fa'
import { RiImageEditLine } from 'react-icons/ri'
import { FiEdit } from 'react-icons/fi'
import { useRef, useState } from 'react'
import { toast } from 'react-toastify';
import defaultImage from '../default company image.png'


import DropdownMenu from './DropdownMenu'
import Input from "./forms/Input";


export default function LocationDetails({ detail, listContact }) {
    const authToken = sessionStorage.getItem("token")
    const editContactForm = useRef(null)
    const addNoteForm = useRef(null)
    const contact = detail.data.crmContactInfo
    const [isContactSaving, setIsContactSaving] = useState(false)
    const [noteTitle, setNoteTitle] = useState('')
    const [noteDetails, setNoteDetails] = useState('')
    const [noteIndex, setNoteIndex] = useState(0)
    const [isEditNoteActive, setIsEditNoteActive] = useState(false)
    const [isNoteUpdating, setIsNoteUpdating] = useState(false)
    const [tag, setTag] = useState(contact.tag)
    const [contactData, setContactData] = useState({
        name: contact?.name,
        email: contact?.email,
        phone: Array.isArray(contact?.phone) ? contact.phone : [''],
        address: Array.isArray(contact?.address) ? contact.address : [],
        avatar: contact?.avatar,
        isActive: contact?.isActive,
        notes: contact?.notes,
        tag: contact?.tag,
        type: contact?.type,
        dataType: contact?.dataType,
        latitude: contact?.latitude,
        longitude: contact?.longitude,
        ratingAndReviews: contact?.ratingAndReviews
    })

    const notes = detail?.data?.crmContactInfo?.notes

    const openEditModal = (e) => {

        e.preventDefault()
        setContactData({
            name: contact?.name,
            email: contact?.email,
            phone: Array.isArray(contact?.phone) ? contact.phone : [''],
            address: Array.isArray(contact?.address) ? contact.address : [''],
            avatar: contact?.avatar,
            isActive: contact?.isActive,
            notes: contact?.notes,
            tag: contact?.tag,
            type: contact?.type,
            dataType: contact?.dataType,
            latitude: contact?.latitude,
            longitude: contact?.longitude,
            ratingAndReviews: contact?.ratingAndReviews
        })
        const editModal = document.getElementById('edit-contact-modal')
        if (editModal) editModal.classList.remove('hidden')
    }

    const handleInputChange = (e) => {
        if (e.target.name == 'phone') {
            setContactData(prevState => {
                const newPhoneList = [...prevState.phone];
                newPhoneList[e.target.id] = e.target.value;
                return { ...prevState, phone: newPhoneList };
            });
        } else if (e.target.name == 'address') {
            setContactData(prevState => {
                const newAddressList = [...prevState.address];
                newAddressList[e.target.id] = e.target.value;
                return { ...prevState, address: newAddressList };
            });
        } else {
            setContactData(prev => { return { ...prev, [e.target.name]: e.target.value } })
        }
    }

    const closeEditModal = (e) => {
        setContactData({
            name: '',
            email: '',
            phone: [''],
            address: [''],
            avatar: '',
            isActive: true,
            notes: [],
            tag: '',
            type: '',
            dataType: 'maps',
            latitude: '',
            longitude: '',
            ratingAndReviews: ''
        })
        document.getElementById('edit-contact-modal').classList.add('hidden')
    }

    const onAvatarChange = (e) => {
        const fileData = e.target.files[0]
        const avatarData = new FormData()
        avatarData.append('file', fileData)
        fetch(`${process.env.REACT_APP_API_URL}/fileUpload`, {
            method: 'POST',
            body: avatarData,
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Image is updated successfully!')
                updateContact(data.data)
            })
    }

    const updateContact = (imgData) => {
        let notesData = []
        if (contact.notes?.length) notesData = contact.notes
        let data = {
            crmContactInfo: contactData
        }
        data.crmContactInfo.notes = notesData
        data.crmContactInfo.avatar = imgData
        data.crmContactInfo.id = detail.id

        data = JSON.stringify(data)
        fetch(`${process.env.REACT_APP_API_URL}/concepts/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: data,
        })
            .then(res => res.json())
            .then(data => {
                listContact()
            })
    }

    const saveContact = (e) => {
        e.preventDefault()
        setIsContactSaving(true)
        // editContactForm.current.reset()
        setContactData({
            name: '',
            email: '',
            phone: [''],
            address: [''],
            avatar: '',
            isActive: true,
            notes: [],
            tag: '',
            type: '',
            dataType: 'maps',
            latitude: '',
            longitude: '',
            ratingAndReviews: ''
        })
        let notesData = []
        if (contact.notes?.length) notesData = contact.notes

        let data = {
            crmContactInfo: contactData
        }

        data.crmContactInfo.notes = notesData
        data.crmContactInfo.isActive = true
        data.crmContactInfo.tag = tag
        data.crmContactInfo.id = detail.id

        data = JSON.stringify(data)
        fetch(`${process.env.REACT_APP_API_URL}/concepts/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: data,
        })
            .then(res => res.json())
            .then(data => {
                setIsContactSaving(false)
                closeEditModal()
                toast.success('Contact is updated successfully!')
                listContact()
            })
    }

    const openDeleteModal = () => {
        const editModal = document.getElementById('delete-contact-modal')
        if (editModal) editModal.classList.remove('hidden')
    }

    const closeDeleteModal = () => {
        document.getElementById('delete-contact-modal').classList.add('hidden')
    }

    const removeContact = () => {
        closeDeleteModal()
        const id = detail?.id
        fetch(`${process.env.REACT_APP_API_URL}/deleteuser?userId=${id}&apiKey=freeschema`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Contact is removed successfully!')
                listContact()
            })
    }

    let editModalEl = (
        <div className="relative z-10 hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='edit-contact-modal'>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">

                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-lg w-full">
                        <div className={`${isContactSaving ? 'pointer-events-none opacity-60' : ''} bg-white px-4 pt-4`}>
                            <form ref={editContactForm} onSubmit={saveContact}>
                                <Input
                                    label="Name"
                                    name="name"
                                    placeholder="Mentor Friends"
                                    value={contactData.name}
                                    onChange={handleInputChange}
                                    required
                                    labelClass='w-full'
                                />

                                <div className='flex flex-col gap-3'>
                                    <Input
                                        label="Email"
                                        name="email"
                                        type='email'
                                        placeholder="you@example.com"
                                        value={contactData?.email}
                                        onChange={handleInputChange}
                                    />
                                    {
                                        contactData?.phone?.length > 0 && contactData?.phone.map((phoneNumber, index) => {
                                            return (
                                                <div className="flex flex-row gap-2" key={index}>
                                                    <Input
                                                        label={"phone " + (index + 1)}
                                                        name='phone'
                                                        type='tel'
                                                        index={index}
                                                        placeholder="+1 2345678901"
                                                        value={contactData.phone[index]}
                                                        onChange={handleInputChange}
                                                        required
                                                        labelClass='w-11/12'
                                                    />
                                                    {
                                                        index == 0 ?
                                                            <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData({ ...contactData, phone: [...contactData.phone, ''] })}>
                                                                <FaPlus />
                                                            </button>
                                                            :
                                                            <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData(prevState => {
                                                                const newPhones = [...prevState.phone];
                                                                newPhones.splice(index, 1);
                                                                return { ...prevState, phone: newPhones };
                                                            })}>
                                                                <FaMinus />
                                                            </button>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        contactData?.address?.length > 0 && contactData.address.map((address, index) => {
                                            return (
                                                <div key={index}>
                                                    <h6 className='text-base text-gray-600 mb-3'>Address {index + 1}</h6>

                                                    <div className="flex flex-row gap-3 mb-2">
                                                        <Input
                                                            name="address"
                                                            index={index}
                                                            placeholder="New York"
                                                            value={contactData.address[index]}
                                                            onChange={handleInputChange}

                                                            labelClass='w-11/12'
                                                        />
                                                        {
                                                            index == 0 ?
                                                                <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData({ ...contactData, address: [...contactData.address, ""] })}>
                                                                    <FaPlus />
                                                                </button>
                                                                :
                                                                <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData(prevState => {
                                                                    const newAddress = [...prevState.address];
                                                                    newAddress.splice(index, 1);
                                                                    return { ...prevState, address: newAddress };
                                                                })}>
                                                                    <FaMinus />
                                                                </button>
                                                        }
                                                    </div>
                                                    <hr />
                                                </div>
                                            )
                                        })
                                    }

                                    <div className='flex flex-rows gap-3'>
                                        <Input
                                            label="Latitude"
                                            name="latitude"
                                            placeholder="27.6693378"
                                            value={contactData?.latitude}
                                            onChange={handleInputChange}
                                            labelClass='w-6/12'
                                        />
                                        <Input
                                            label="Longitude"
                                            name="longitude"
                                            placeholder="85.315306"
                                            value={contactData?.longitude}
                                            onChange={handleInputChange}
                                            labelClass='w-6/12'
                                        />
                                    </div>

                                    <div className="bg-gray-50 py-3 flex justify-end gap-2">
                                        <button type="button" onClick={closeEditModal} className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">Cancel</button>
                                        <button type="submit" className="btn-primary px-3 py-2">
                                            {!isContactSaving ? 'Save Detail' : 'Saving...'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

    let deleteModalEl = (
        <div className="relative z-10 hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='delete-contact-modal'>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 max-w-lg w-full">
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Remove Location</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">Are you sure you want to remove the contact from your location list? The location will be permanently removed. This action cannot be undone.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button type="button" onClick={removeContact} className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 sm:ml-3 sm:w-auto">Remove Location</button>
                            <button type="button" onClick={closeDeleteModal} className="mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const addNote = (e) => {
        e.preventDefault()
        setIsNoteUpdating(true)
        const currentDate = new Date()
        const noteAddedTime = currentDate.toISOString()
        const noteData = {
            'title': noteTitle,
            'details': noteDetails,
            'addedAt': noteAddedTime,
        }

        let totalNoteData = contact.notes
        if (totalNoteData?.length) {
            if (isEditNoteActive) totalNoteData.splice(noteIndex, 1)
            totalNoteData.unshift(noteData)
        } else {
            totalNoteData = [noteData]
        }

        let data = {
            crmContactInfo: {
                name: contact?.name,
                email: contact?.email,
                phone: Array.isArray(contact?.phone) ? contact.phone : [''],
                address: Array.isArray(contact?.address) ? contact.address : [],
                avatar: contact?.avatar,
                isActive: contact?.isActive,
                notes: contact?.notes,
                tag: contact?.tag,
                type: contact?.type,
                dataType: contact?.dataType,
                latitude: contact?.latitude,
                longitude: contact?.longitude,
                ratingAndReviews: contact?.ratingAndReviews
            }
        }


        data.crmContactInfo.notes = totalNoteData
        data.crmContactInfo.id = detail.id

        data = JSON.stringify(data)

        fetch(`${process.env.REACT_APP_API_URL}/concepts/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: data,
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Note is saved successfully!')
                setIsNoteUpdating(false)
                closeNoteModal()
                listContact()
            })
    }

    const openNoteModal = () => {
        document.getElementById('add-note-modal').classList.remove('hidden')
    }

    const closeNoteModal = () => {
        document.getElementById('add-note-modal').classList.add('hidden')
        setNoteTitle('')
        setNoteDetails('')
        setIsEditNoteActive(false)
        setIsNoteUpdating(false)
    }

    let noteModalEl = (
        <div className="relative z-10 hidden w-50" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='add-note-modal'>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">

                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left w-1/2 shadow-xl transition-all">
                        {/* <div className="bg-white px-4 pt-4"> */}
                        <div className={`${isNoteUpdating ? 'pointer-events-none opacity-60' : ''} bg-white px-4 pt-4`}>
                            <form ref={addNoteForm} onSubmit={addNote}>
                                <div className='flex flex-col gap-3'>

                                    <label className="block">
                                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                            Title
                                        </span>
                                        <input type="text" name="email" value={noteTitle} onChange={e => setNoteTitle(e.target.value)} className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Sample title..." />
                                    </label>
                                    <label className="block">
                                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                            Details
                                        </span>
                                        <textarea value={noteDetails} onChange={e => setNoteDetails(e.target.value)} placeholder="I will ..." className="mt-1 px-3 py-2 resize-none h-60 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" />
                                    </label>

                                    <div className="bg-gray-50 py-3 flex justify-end gap-2">
                                        <button type="button" onClick={closeNoteModal} className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">Cancel</button>
                                        <button type="submit" className="btn-primary px-3 py-2 text-sm">
                                            {!isNoteUpdating ?
                                                (isEditNoteActive ? 'Save Note' : 'Add Note')
                                                :
                                                'Updating...'
                                            }

                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

    const updateTag = (e) => {
        const tagValue = e.target.getAttribute('value')
        setTag(tagValue)

        let data = {
            compositionId: detail?.id,
            patcher: {
                tag: tagValue
            }
        }
        data = JSON.stringify(data)

        fetch(`${process.env.REACT_APP_API_URL}/patch/composition`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: data,
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Tag is updated successfully!')
                listContact()
            })
    }

    const editNote = (editNoteIndex) => {
        setNoteIndex(editNoteIndex)
        setIsEditNoteActive(true)
        const noteData = contact.notes[editNoteIndex]
        setNoteDetails(noteData.details)
        setNoteTitle(noteData.title)
        openNoteModal()
    }



    let noteList = []
    if (notes?.length > 0) {
        noteList = notes.map((note, index) => {
            let noteAddedTime
            if (note.addedAt) {
                noteAddedTime = new Date(note.addedAt).toLocaleString()
            }
            return (
                <>
                    <div key={note.title} className="group relative p-4 pb-6 shadow-md my-4 border rounded-md overflow-hidden bg-white ">
                        <p className="text-right text-sm text-gray-400">{noteAddedTime}</p>
                        <h4 className="mb-3">{note.title}</h4>
                        <p className="text-base font-medium text-gray-600 mt-2">{note.details}</p>
                        <FiEdit title="Edit Note" onClick={() => { editNote(index) }} className="invisible group-hover:visible absolute right-3 bottom-3 text-indigo-400 hover:text-indigo-600 cursor-pointer" />
                    </div>
                </>
            )
        })
    }

    return (
        <>
            <div className="grid grid-cols-12" key={contact?.id}>
                <div className='p-4 flex items-center md:h-screen flex-col col-span-12 lg:col-span-8 border-t md:border-none pt-4 md:pt-4 overflow-y-auto'>
                    <div className="flex flex-row items-center text-sm bg-gray-200 rounded-full item py-1 px-3 gap-1 mb-5">
                        {contact.tag ? contact.tag[0].toUpperCase() + contact.tag.slice(1) : ' Other'}
                        <BsCircleFill className={`${contact.tag === 'client' ? 'text-green-400' : (contact.tag === 'lead' ? 'text-orange-400' : (contact.tag === 'prospect' ? 'text-sky-400' : 'text-gray-400'))}`} />
                    </div>

                    <div className="relative">
                        <img src={`${contact.avatar || defaultImage}`} alt={contact.name} className="h-40 w-40 flex-none rounded-full border object-cover" />

                        <div className="absolute right-1 bottom-1 ">
                            <label title="Click to upload" htmlFor="avatar" className="cursor-pointer flex items-center gap-4 p-2 group border border-dashed rounded-full active:duration-75 active:scale-95 bg-gray-100">
                                <div className="w-max relative">
                                    <RiImageEditLine className="text-indigo-400" />
                                </div>
                            </label>
                            <input hidden=""
                                type="file"
                                name="avatar"
                                id="avatar"
                                accept=".png, .jpg, .jpeg"
                                className="hidden"
                                onChange={onAvatarChange}
                            />
                        </div>
                    </div>

                    <h1 className='text-2xl mt-4'>{contact.name}</h1>
                    <p className='text-lg mb-3'>{contact.email}</p>
                    <div className="flex flex-row gap-4 mt-3">
                        <button type="button" onClick={openEditModal} className="bg-indigo-400 hover:bg-indigo-600 py-2 px-3 rounded-md text-sm text-gray-100 flex flex-row items-center gap-2"><FaUserEdit /> Edit Details</button>
                        <button type="button" onClick={openDeleteModal} className="bg-red-400 hover:bg-red-600 py-2 px-3 rounded-md text-sm text-gray-100 flex flex-row items-center gap-2"><FaUserMinus /> Remove Location</button>
                    </div>
                    <div className='flex flex-rows mt-6 mb-10'>
                        <div className='flex flex-col items-center mx-2'>
                            <a href={`tel:${contact.phone}`} className={`${contact?.phone !== undefined ? 'block p-3 rounded-full bg-gray-300 hover:bg-gradient-to-r hover:from-pink-500 hover:to-orange hover:text-gray-100' : 'pointer-events-none block p-3 rounded-full bg-gray-300 hover:bg-gradient-to-r hover:from-pink-500 hover:to-orange hover:text-gray-100 '}`}><BsFillTelephoneFill /></a>
                            <span className='text-base mt-2'>Call</span>
                        </div>
                        <div className='flex flex-col items-center mx-2'>
                            <a href={`mailto:${contact?.email}`} className={`${contact?.email !== undefined ? 'block p-3 rounded-full bg-gray-300 hover:bg-gradient-to-r hover:from-pink-500 hover:to-orange hover:text-gray-100' : 'pointer-events-none block p-3 rounded-full bg-gray-300 hover:bg-gradient-to-r hover:from-pink-500 hover:to-orange hover:text-gray-100 '}`}>
                                <BsFillEnvelopeFill />
                            </a>
                            <span className='text-base mt-2'>Email</span>
                        </div>
                        <div className='flex flex-col items-center mx-2'>
                            <DropdownMenu updateTag={updateTag} value='prospect' />
                            <span className='text-base mt-2'>Tag</span>
                        </div>

                        <div className='flex flex-col items-center mx-2' onClick={openNoteModal}>
                            <span className='block p-3 rounded-full bg-gray-300 hover:bg-gradient-to-r hover:from-pink-500 hover:to-orange hover:text-gray-100 cursor-pointer'><BsFillFileEarmarkTextFill /></span>
                            <span className='text-base mt-2'>Note</span>
                        </div>
                    </div>


                    {/* Activity */}
                    <div className="p-2 w-full">
                        <p className="text-base">Contact activity</p>
                        {noteList.length ? noteList :
                            <>
                                <div className="p-4 pb-6 shadow-md my-4 border text-center text-gray-600 rounded-md overflow-hidden bg-white ">
                                    <BsFillFileEarmarkExcelFill size={36} className="text-gray-500 m-auto mb-3" />
                                    <p>No activites found.</p>
                                    <p className="text-sm text-gray-500 mt-4">Add a note for the contact.</p>
                                </div>
                            </>
                        }
                    </div>

                    {/* Map */}
                    {/* <div className="p-2 w-full">
                        <p className="text-base">Map</p>
                        {contactData.latitude && contactData?.longitude ?
                            <div id="map" style={{ width: '770px', height: '400px' }} ref={mapRef}></div> :
                            <>
                                <div className="p-4 pb-6 shadow-md my-4 border text-center text-gray-600 rounded-md overflow-hidden bg-white ">
                                    <BsMapFill size={36} className="text-gray-500 m-auto mb-3" />
                                    <p className="text-sm text-gray-500 mt-4">Map not available for the data.</p>
                                </div>
                            </>
                        }
                    </div> */}


                </div>

                <div className='h-screen border-l-2 flex flex-col col-span-4 invisible lg:visible overflow-y-auto'>
                    <div className="p-4 my-4">
                        <h2 className="mb-4">General Info</h2>
                        <p className="text-sm text-gray-600 mb-1">Name</p>
                        <p className="text-base text-gray-800">{contact?.name}</p>

                        {contact.phone && (
                            <>
                                <p className='mt-2 text-sm text-gray-600 mb-1'>Phone</p>
                                {contact.phone.map((phone, index) => (
                                    <div key={index} className="mb-3">
                                        <p className="text-base text-gray-800">{phone}</p>
                                    </div>
                                ))}
                            </>
                        )}

                        {
                            contact?.url &&
                            <div className="mt-5">
                                <p className="text-sm text-gray-600">URL</p>
                                <a
                                    href={`${contact?.url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm text-indigo-600 underline cursor-pointer"
                                >
                                    {contact?.url}
                                </a>
                            </div>
                        }



                        {
                            contact?.website &&
                            <div className="mt-5">
                                <p className="text-sm text-gray-600">Website</p>
                                <a
                                    href={`https://${contact?.website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm text-indigo-600 underline cursor-pointer"
                                >
                                    {contact?.website}
                                </a>
                            </div>
                        }

                    </div>
                    {contact?.address && (
                        <div className="border-t-2 p-4 my-4">
                            <h2 className="mb-4">Address</h2>
                            {contact.address.map((address, index) => (
                                <div key={index} className="mb-3">
                                    <p className="text-base text-gray-800">{address}</p>
                                </div>
                            ))}
                        </div>
                    )}


                    <div className="border-t-2 p-4 my-4">
                        <h2 className="mb-4">Other Info</h2>
                        {contact?.ratingAndReviews &&
                            <>
                                <h6 className="mb-2">Rating</h6>
                                <p className="text-sm text-gray-600">{contact?.ratingAndReviews}</p>
                            </>
                        }
                        {contact?.type &&
                            <>
                                <h6 className="mb-2 mt-1">Type</h6>
                                <p className="text-sm text-gray-600">{contact?.type}</p>
                            </>
                        }
                    </div>
                </div>
            </div >
            {editModalEl}
            {deleteModalEl}
            {noteModalEl}
        </>
    )
}