import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

export default function LoginForm() {
  const navigate = useNavigate()
  // const [loginEmail, setLoginEmail] = useState(null)
  // const [loginPassword, setLoginPassword] = useState(null)

  const [isPasswordSeen, setIsPasswordSeen] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    const loginEmail = document.getElementById('login-email')
    const loginPassword = document.getElementById('login-password')
    loginEmail.classList.remove('border-red-500')
    loginPassword.classList.remove('border-red-500')
    loginEmail.classList.add('border-slate-300')
    loginPassword.classList.add('border-slate-300')

    setLoginFormData(prevFormData => (
      {
        ...prevFormData,
        [e.target.name]: e.target.value
      }
    ))
  }

  const onLogin = (e) => {
    e.preventDefault()
    setErrMessage(null)

    const loginBtn = document.getElementById('login-btn')
    const loginEmail = document.getElementById('login-email')
    const loginPassword = document.getElementById('login-password')
    loginBtn.setAttribute('disabled', '')
    loginBtn.classList.add('disabled:opacity-70')
    loginBtn.classList.remove('hover:bg-indigo-800')
    loginBtn.innerText = 'LOGGING IN...'

    let data = {
      email: loginFormData.email,
      password: loginFormData.password
    }

    data = JSON.stringify(data)

    fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {
          const token = data.data.token
          const userInfo = {
            email: data.data.email,
            id: data.data.entity[0].id
          }
          setStorage(token, userInfo)
          navigate('/dashboard')
        } else {
          setErrMessage('Login failed. Email or password is not matched')
          loginBtn.removeAttribute('disabled', '')
          loginBtn.classList.remove('disabled:opacity-70')
          loginBtn.classList.add('hover:bg-indigo-800')
          loginBtn.innerText = 'LOGIN'

          loginEmail.classList.remove('border-slate-300')
          loginPassword.classList.remove('border-slate-300')
          loginEmail.classList.add('border-red-500')
          loginPassword.classList.add('border-red-500')
        }
      })
  }

  const setStorage = (token, userInfo) => {
    sessionStorage.setItem("isLoggedIn", "true")
    sessionStorage.setItem("token", token)
    sessionStorage.setItem("email", userInfo.email)
    sessionStorage.setItem("id", userInfo.id)
  }

  return (
    <div className="w-full mx-auto flex">
      <div className='md:w-1/2 flex-none min-h-screen flex-col justify-center relative'>
        <img src='https://images.unsplash.com/photo-1611532736597-de2d4265fba3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80' alt='iPad on table with drawsing in the screen' className='absolute w-full h-full object-cover' />
      </div>

      <div className='w-full md:w-1/2 flex min-h-screen flex-col justify-center p-10 md:p-20 lg:p-40 lg:pt-20'>
        <h1 className="text-3xl">
          Welcome back! ✨
        </h1>
        <form onSubmit={onLogin}>
          <label className="block my-4">
            <span className="block text-sm font-medium text-slate-700">Email</span>
            <input
              type="email"
              id='login-email'
              placeholder="john@email.com"
              name='email'
              value={loginFormData.email}
              // onChange={(e) => setLoginEmail(e.target.value)} 
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
            />
          </label>
          <label className="block my-4">
            <span className="block text-sm font-medium text-slate-700">Password</span>
            <div className="relative">
              <input
                // type="password"
                type={`${isPasswordSeen ? 'text' : 'password'}`}
                id='login-password'
                placeholder="Your password"
                name='password'
                value={loginFormData.password}
                onChange={handleChange}
                // onChange={(e) => setLoginPassword(e.target.value)} 
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              />
              {loginFormData.password &&
                <button type="button" className="absolute right-2 top-2.5" onClick={() => setIsPasswordSeen(!isPasswordSeen)}>
                  {isPasswordSeen ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
              }
            </div>
          </label>

          {errMessage && <p className="text-red-500 mb-5">{errMessage}</p>}

          <div className='flex justify-between mb-5 pt-2'>
            {/* <button type="submit" id="login-btn" className="bg-indigo-600 text-white uppercase text-sm leading-6 font-medium py-2 px-3 rounded-lg">
              Login
            </button> */}
            <div className="flex items-center gap-2 text-sm">
              <input type="checkbox" id="remember-me" className="hover:cursor-pointer" />
              <label htmlFor="remember-me" className="hover:cursor-pointer">Remember me</label>
            </div>
            <Link to='/forgotPassword' className='underline text-sm hover:text-pink-600'>Forgot Password?</Link>
          </div>

          <div className='flex justify-between'>
            <button type="submit" id="login-btn" className="btn-primary uppercase px-6">
              Login
            </button>
          </div>
        </form>

        <hr className='my-5' />
        <p>Don't have an account? <Link to='/register' className="underline text-pink-400 hover:text-pink-600 leading-6 font-medium">Register</Link></p>

      </div>
    </div>
  )
}