import { FaRegTimesCircle, FaPlus, FaMinus } from 'react-icons/fa'
import { TiContacts } from 'react-icons/ti'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { toast } from 'react-toastify';
import defaultImage from '../default company image.png'
import Input from '../components/forms/Input';
import LocationDetails from '../components/LocationDetails';

export default function Location() {
    const authToken = sessionStorage.getItem("token")
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        phone: [''],
        address: [''],
        avatar: '',
        isActive: true,
        notes: [],
        tag: '',
        type: '',
        dataType: 'maps',
        latitude: '',
        longitude: '',
        ratingAndReviews: ''
    })
    const [contacts, setContacts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [contactAllDetails, setContactAllDetails] = useState(null)
    const [active, setActive] = useState(null)
    const [isSearched, setIsSearched] = useState(false)
    const [searchedList, setSearchedList] = useState(null)

    const handleInputChange = (e) => {
        if (e.target.name == 'phone') {
            setContactData(prevState => {
                const newPhoneList = [...prevState.phone];
                newPhoneList[e.target.id] = e.target.value;
                return { ...prevState, phone: newPhoneList };
            });
        } else if (e.target.name == 'address') {
            setContactData(prevState => {
                const newAddressList = [...prevState.address];
                newAddressList[e.target.id] = e.target.value;
                return { ...prevState, address: newAddressList };
            });
        } else {
            setContactData(prev => { return { ...prev, [e.target.name]: e.target.value } })
        }
    }

    const getContacts = () => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/concepts/list?type=crmContactInfo`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
        }).then(res => res.json())
            .then(data => {
                setIsLoading(false)
                const contactsData = data.data.filter(data => data.data.crmContactInfo.isActive == 'True' && data?.data?.crmContactInfo?.dataType == 'maps')
                const sortedContacts = contactsData.sort((a, b) => a.data.crmContactInfo.name.localeCompare(b.data.crmContactInfo.name))
                setContactAllDetails(sortedContacts[0])
                setContacts(sortedContacts)
                setActive(sortedContacts[0])
            })
    }

    const getSearchedContact = () => {
        const contactsData = searchedList.data.filter(data => data.data.crmContactInfo.isActive == 'True' && data?.data?.crmContactInfo?.dataType == 'maps')
        setContactAllDetails(contactsData[0])
        setContacts(contactsData)
        setActive(contactsData[0])
    }

    const contactList = contacts.map(contact => {
        const contactInfo = contact.data.crmContactInfo
        const imgUrl = contactInfo.avatar ? contactInfo.avatar : defaultImage;
        // const imgUrl = `${contactInfo.avatar}`
        return (
            <Link to={`/location/${contact?.id}`} key={contact?.id} onClick={() => { setContactAllDetails(contact) }}>
                <li onClick={() => { setActive(contact) }} className={`flex items-center py-4 cursor-pointer hover:bg-pink-100 hover:outline-gray-200  p-4 ${active === contact && 'bg-pink-100'}`}>
                    <img src={imgUrl} alt={contactInfo?.name} className="h-10 w-10 flex-none rounded-full border object-cover" />
                    <div className="ml-4 flex-auto">
                        <div className="text-base">{contactInfo.name}</div>
                    </div>
                </li>
            </Link>
        )
    })

    const openModal = () => {
        document.getElementById('create-contact-modal').classList.remove('hidden')
    }

    const emptyContactList = (
        <div className="flex justify-center">
            <div className='mt-20 flex flex-col items-center text-gray-500 gap-4 bg-white rounded-xl shadow-sm p-14'>
                <TiContacts size={64} className='bg-gray-200 p-4 rounded-full' />
                <p className='text-base'>There is no saved locations.</p>
                <button
                    onClick={openModal}
                    className="btn-primary uppercase"
                >
                    Add location
                </button>
            </div>
        </div>
    )

    const loadingState = (
        <div className='mt-20 flex flex-col items-center text-gray-400 gap-4'>
            <p className='text-base'>Loading...</p>
        </div>
    )

    const createContact = (e) => {
        e.preventDefault()
        closeModal()
        const uploadFile = document.getElementById('avatar-input')
        const fileData = uploadFile.files[0]
        const avatarData = new FormData()
        avatarData.append('image', fileData)
        fetch(`${process.env.REACT_APP_IMAGE_URL}/UploadImage`, {
            method: 'POST',
            body: avatarData,
        })
            .then(res => res.json())
            .then(data => {
                createNewContact(data.data)
            })

        const createNewContact = (imgData) => {
            let data = {
                crmContactInfo: contactData
            }
            data.crmContactInfo.avatar = imgData
            data = JSON.stringify(data)

            fetch(`${process.env.REACT_APP_API_URL}/concepts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: data,
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Contact is created successfully!')
                    setContactData({
                        name: '',
                        email: '',
                        phone: [''],
                        address: [''],
                        avatar: '',
                        isActive: true,
                        notes: [],
                        tag: '',
                        type: '',
                        dataType: 'maps',
                        latitude: '',
                        longitude: '',
                        ratingAndReviews: ''
                    })
                    getContacts()
                })
        }
    }

    useEffect(() => {
        // return () => {
        getContacts()
        // }
    }, [])

    const handleSearch = (e) => {
        const keyword = e.target.value?.toLowerCase()
        if (keyword) setIsSearched(true)
        else setIsSearched(false)
        const searchedContacts = contacts.filter(contact => contact.data.crmContactInfo.name?.toLowerCase().includes(keyword))

        const filteredList = searchedContacts.map(contact => {
            const contactInfo = contact.data.crmContactInfo
            const imgUrl = `${contactInfo.avatar}`
            return (
                <Link to={`/location/${contact?.id}`} key={contact.id} onClick={() => { setContactAllDetails(contact) }}>
                    <li onClick={() => { setActive(contact) }} className={`flex items-center py-4 cursor-pointer hover:bg-pink-100 hover:outline-gray-200 p-4 ${active === contact && 'bg-pink-200'}`}>
                        <img src={imgUrl} alt={contactInfo?.name} className="h-10 w-10 flex-none rounded-full border object-cover" />
                        <div className="ml-4 flex-auto">
                            <div className="text-base">{contactInfo?.name}</div>
                        </div>
                    </li>
                </Link>
            )
        })
        setSearchedList(filteredList)
    }

    const closeModal = () => {
        document.getElementById('create-contact-modal').classList.add('hidden')
    }

    const createModalEl = (
        <div className="relative z-10 hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='create-contact-modal'>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">

                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-lg w-full">
                        <div className="bg-white px-4 pt-4">
                            <form onSubmit={createContact}>
                                <div className='flex flex-col gap-3'>
                                    <Input
                                        label="name"
                                        name="name"
                                        placeholder="Mentor Friends"
                                        value={contactData.name}
                                        onChange={handleInputChange}
                                        required
                                        labelClass='w-full'
                                    />

                                    <Input
                                        label="Email"
                                        name="email"
                                        type='email'
                                        placeholder="you@example.com"
                                        value={contactData.email}
                                        onChange={handleInputChange}

                                    />
                                    {
                                        contactData?.phone?.length > 0 && contactData.phone.map((phoneNumber, index) => {
                                            return (
                                                <div className="flex flex-row gap-2" key={index}>
                                                    <Input
                                                        label={"phone " + (index + 1)}
                                                        name='phone'
                                                        type='tel'
                                                        index={index}
                                                        placeholder="+1 2345678901"
                                                        value={contactData.phone[index]}
                                                        onChange={handleInputChange}

                                                        labelClass='w-11/12'
                                                    />
                                                    {
                                                        index == 0 ?
                                                            <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData({ ...contactData, phone: [...contactData.phone, ''] })}>
                                                                <FaPlus />
                                                            </button>
                                                            :
                                                            <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData(prevState => {
                                                                const newPhones = [...prevState.phone];
                                                                newPhones.splice(index, 1);
                                                                return { ...prevState, phone: newPhones };
                                                            })}>
                                                                <FaMinus />
                                                            </button>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        contactData.address.map((address, index) => {
                                            return (
                                                <div key={index}>
                                                    <h6 className='text-base text-gray-600 mb-3'>Address {index + 1}</h6>
                                                    <div className="flex flex-row gap-3 mb-2">
                                                        <Input
                                                            name="address"
                                                            index={index}
                                                            placeholder="New york"
                                                            value={contactData.address[index]}
                                                            onChange={handleInputChange}

                                                            labelClass='w-11/12'
                                                        />
                                                        {
                                                            index == 0 ?
                                                                <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData({ ...contactData, address: [...contactData.address, ''] })}>
                                                                    <FaPlus />
                                                                </button>
                                                                :
                                                                <button type='button' className="btn-primary p-2 mt-auto w-1/12" onClick={() => setContactData(prevState => {
                                                                    const newAddress = [...prevState.address];
                                                                    newAddress.splice(index, 1);
                                                                    return { ...prevState, address: newAddress };
                                                                })}>
                                                                    <FaMinus />
                                                                </button>
                                                        }
                                                    </div>
                                                    <hr />
                                                </div>
                                            )
                                        })
                                    }

                                    <div className='flex flex-rows gap-3'>
                                        <Input
                                            label="Latitude"
                                            name="latitude"
                                            placeholder="27.6693378"
                                            value={contactData?.latitude}
                                            onChange={handleInputChange}
                                            labelClass='w-6/12'
                                        />
                                        <Input
                                            label="Longitude"
                                            name="longitude"
                                            placeholder="85.315306"
                                            value={contactData?.longitude}
                                            onChange={handleInputChange}
                                            labelClass='w-6/12'
                                        />
                                    </div>

                                    <label className="block">
                                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                            Avatar
                                        </span>
                                        <input type="file" name="avatar" id='avatar-input' className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" />
                                    </label>
                                    <div className="bg-gray-50 py-3 flex justify-end gap-2">
                                        <button type="button" onClick={closeModal} className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">Cancel</button>
                                        <button type="submit" className="btn-primary px-3 py-2 text-sm">Create New location</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

    return (
        <>
            <div className="grid grid-cols-8">
                <div className="h-[50vh] md:h-screen border-r-2 col-span-8 md:col-span-4 lg:col-span-2 overflow-y-auto">
                    <div className='flex justify-between items-center p-4'>
                        <h2>Locations </h2>
                        <button type='button' onClick={openModal} className='btn-primary capitalize text-sm' title='New Contact'>
                            {/* <FaRegTimesCircle className='text-indigo-500 rotate-45 hover:text-indigo-800 inline-block'/>  */}
                            <FaRegTimesCircle className='text-white-500 rotate-45 inline-block mr-1' />
                            Add location
                        </button>
                    </div>

                    <div className='px-4'>
                        <input type="search" onChange={handleSearch} className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md text-sm focus:ring-1" placeholder="Search location..." />
                    </div>

                    <ul className='mt-2'>
                        {!isSearched ?
                            (!isLoading ?
                                (contactList.length ?
                                    contactList : emptyContactList
                                ) : loadingState
                            ) :
                            (searchedList.length ?
                                searchedList : emptyContactList
                            )
                        }
                    </ul>
                </div>
                <div className="h-screen w-auto col-span-8 md:col-span-4 lg:col-span-6 visible">
                    {!isSearched ?
                        (!isLoading ?
                            (contactList?.length ?
                                <LocationDetails detail={contactAllDetails} listContact={getContacts} />
                                : <div className='mt-32'>{emptyContactList}</div>
                            ) : loadingState
                        ) :
                        (searchedList?.length ?
                            <LocationDetails detail={contactAllDetails} listContact={getSearchedContact} />
                            : <div className='mt-32'>{emptyContactList}</div>
                        )
                    }
                </div>
            </div>
            {createModalEl}

        </>
    )
}