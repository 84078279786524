import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { BsCircleFill, BsFillTagFill } from 'react-icons/bs'

export default function Dropdown({ updateTag }) {
  return (
    <Menu as="div" className="relative flex text-left">
        <Menu.Button className="inline-flex w-full justify-center rounded-md  text-sm font-semibold text-gray-900 shadow-sm ">
          <div className='p-3 w-11 h-11 rounded-full bg-gray-300 hover:bg-gradient-to-r hover:from-pink-500 hover:to-orange hover:text-gray-100 cursor-pointer'>
            <BsFillTagFill className="-mr-1 h-5 w-5 " />
          </div>
        </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-14 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-sm">Set tag for the contact</p>
          </div>
          <div className="py-1">

            <Menu.Item>
            <button
              onClick={updateTag}
              value='prospect'
              className='w-full text-gray-700 flex items-center justify-between gap-2 px-4 py-2 text-left text-sm hover:bg-gray-100'
            >
              Prospect <BsCircleFill className='text-sky-400' />
            </button>
            </Menu.Item>
            <Menu.Item>
            <button
              onClick={updateTag}
              value='lead'
              className='w-full text-gray-700 flex items-center justify-between gap-2 px-4 py-2 text-left text-sm hover:bg-gray-100'
            >
              Lead <BsCircleFill className='text-orange-400' />
            </button>
            </Menu.Item>
            <Menu.Item>
            <button
              onClick={updateTag}
              value='client'
              className='w-full text-gray-700 flex items-center justify-between gap-2 px-4 py-2 text-left text-sm hover:bg-gray-100'
            >
              Customer <BsCircleFill className='text-green-400' />
            </button>
            </Menu.Item>
            <Menu.Item>
            <button
              onClick={updateTag}
              value='other'
              className='w-full text-gray-700 flex items-center justify-between gap-2 px-4 py-2 text-left text-sm hover:bg-gray-100'
            >
              Other <BsCircleFill className='text-gray-400' />
            </button>
            </Menu.Item>
            
          </div>

        </Menu.Items>
      </Transition>
    </Menu>
  )
}
