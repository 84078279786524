import React, { useState, useEffect, useRef } from "react"
import * as d3 from 'd3'
import Leads from "./Leads"
import Customers from "./Customers"

export default function Dashboard() {
  const [authToken, setAuthToken] = useState(sessionStorage.getItem("token"))
  const [isLoading, setIsLoading] = useState(true)
  const [contactListData, setContactListData] = useState([])
  const svgRef = useRef()

  const getContacts = () => {
    setIsLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/concepts/list?type=crmContactInfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then(res => res.json())
      .then(resData => {
        setIsLoading(false)

        const contactsData = resData.data.filter(data => data.data.crmContactInfo.isActive == 'True')
        let prospectsCount = 0
        let leadsCount = 0
        let clientsCount = 0
        let othersCount = 0

        contactsData.forEach(contact => {
          const contactTag = contact.data.crmContactInfo.tag
          if (contactTag === 'lead') leadsCount += 1
          else if (contactTag === 'client') clientsCount += 1
          else if (contactTag === 'prospect') prospectsCount += 1
          else othersCount += 1
        })

        const dataArr = []

        if (leadsCount) dataArr.push({ 'property': `lead (${leadsCount})`, 'count': leadsCount })
        if (clientsCount) dataArr.push({ 'property': `client (${clientsCount})`, 'count': clientsCount })
        if (prospectsCount) dataArr.push({ 'property': `prospect (${prospectsCount})`, 'count': prospectsCount })
        if (othersCount) dataArr.push({ 'property': `other (${othersCount})`, 'count': othersCount })

        setContactListData(dataArr)

        setTimeout(() => {
          renderChart(dataArr)
        }, 500);

      })
  }

  const renderChart = (data) => {
    // setting up svg container
    const w = 235
    const h = 235
    const radius = w / 2

    const svg = d3.select(svgRef.current)
      .attr('width', w)
      .attr('height', h)
      .style('overflow', 'visible')
      .style('padding-top', '150px')
      .style('padding-left', '115px')
      .style('position', 'absolute')
      .style('text-align', 'center')
      .style('font-size', '16px')

    // setting up chart
    const formattedData = d3.pie().value(d => {
      return d.count
    })(data)
    const arcGenerator = d3.arc().innerRadius(60).outerRadius(radius)
    const color = d3.scaleOrdinal().range(d3.schemeSet1)

    // setting up svg data
    svg.selectAll()
      .data(formattedData)
      .join('path')
      .attr('d', arcGenerator)
      .attr('fill', d => color(d.value))
      .style('opacity', 0.7)

    // setting up annotation
    svg.selectAll()
      .data(formattedData)
      .join('text')
      .text(d => d.data.property)
      .attr('transform', d => `translate(${arcGenerator.centroid(d)})`)
      .style('text-anchor', 'middle')

  }

  useEffect(() => {
    // return () => {
    if (sessionStorage.getItem('token')) {
      getContacts()
    }
    // }
  }, [authToken])

  useEffect(() => {
    setAuthToken(sessionStorage.getItem('token'))
  }, [sessionStorage])


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full p-8">
          <div className="bg-white px-6 py-4 rounded-xl">
            <div className="">
              <section className="">
                <div className="p-4 pb-72">
                  <h1 className="pb-2 border-b border-gray-100">Charts ✨</h1>
                  {isLoading ?
                    (<p className="mt-5 text-gray-500">Loading...</p>)
                    :
                    (contactListData.length > 0 ?
                      <>
                        <div>
                          <svg ref={svgRef}></svg>
                        </div>

                        <div>

                        </div>
                      </>
                      :
                      <p className="mt-5 text-gray-500">No contacts yet.</p>
                    )
                  }

                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="md:w-1/2 sm:w-full">
          <Leads />
        </div>

        <div className="md:w-1/2 sm:w-full">
          <Customers />
        </div>
      </div>


    </>
  )
}