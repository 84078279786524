import React, { useState } from "react"
import { HiMenuAlt3 } from 'react-icons/hi'
import { MdOutlineDashboard, MdOutlineContacts, MdPeopleOutline, MdPersonOutline } from 'react-icons/md'
import { BiUser } from 'react-icons/bi'
import { CiLocationOn } from 'react-icons/ci'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { BsFillTriangleFill } from "react-icons/bs"

export default function Dashboard() {
  const navigate = useNavigate()
  const menus = [
    { name: 'Dashboard', link: '/dashboard', icon: MdOutlineDashboard },
    { name: 'Entity', link: '/entity', icon: MdOutlineContacts },
    { name: 'Customers', link: '/customers', icon: MdPeopleOutline },
    { name: 'Leads', link: '/leads', icon: MdPersonOutline },
    { name: 'Prospects', link: '/prospects', icon: BiUser },
    { name: 'Locations', link: '/location', icon: CiLocationOn },
    { name: 'Boom Console', link: 'https://boomconsole.com/account', icon: BsFillTriangleFill, blank: true },
    { name: 'Logout', link: '/', icon: RiLogoutBoxLine },
  ]

  const logout = (e) => {
    e.preventDefault()
    sessionStorage.clear()
    navigate('/')
  }
  const [open, setOpen] = useState(true)

  return (
    <>
      <div className={`${open ? 'w-16 md:w-72' : 'w-16'} duration-500 bg-gradient-138 from-black-light to-black-dark min-h-screen text-gray-100 px-4`}>
        <div className="py-3 flex justify-between items-center">
          <span className={`${!open && 'opacity-0 hidden'} flex items-center gap-3 invisible md:visible duration-500 whitespace-pre font-bold text-gray-200 text-xl ml-3`}>
            <img src="/android-chrome-192x192.png" alt="logo" width={30} />
            Mentor CRM
          </span>
          <HiMenuAlt3
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="mt-4 flex flex-col gap-1 relative">
          {menus.map((menu, i) => (
            <NavLink onClick={menu.name === 'Logout' ? logout : null} key={i} to={menu.link} target={menu?.blank && "_blank"} className={`${menu.margin ? 'mt-5' : ''} group flex items-center text-sm gap-3.5 font-medium transform duration-300 ${open ? 'p-3' : 'p-1.5'} opacity-70 hover:opacity-100 hover:bg-white hover:bg-opacity-10 rounded-md`}>
              <div>{React.createElement(menu?.icon, { size: '20' })}</div>
              <h2
                style={{
                  transitionDelay: `${i + 2}00ms`,
                }}
                className={`${!open ? 'opacity-0 translate-x-28 overflow-hidden' : ''} invisible md:visible whitespace-pre duration-500`}>{menu.name}
              </h2>
              <h2 className={`${open && 'md:hidden'} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>
                {menu.name}
              </h2>
            </NavLink>
          ))
          }
        </div>

      </div>
    </>
  )
}